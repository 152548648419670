import request from "@/utils/request";
/**
 * 获取key列表
 */
export const getkeys = () => {
  return request({
    url: "/users/apikeys",
  });
};
/**
 * 添加key
 */
export const addkeys = () => {
  return request({
    url: "/users/apikey",
    method: "POST",
  });
};
/**
 * 删除key
 */
export const removekeys = (id) => {
  return request({
    url: `/users/apikey/${id}`,
    method: "DELETE",
  });
};
/**
 * 编辑key
 */
export const editkeys = (data) => {
  return request({
    url: `/users/apikey/${data.key}`,
    method: "PUT",
    data,
  });
};
