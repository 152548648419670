<template>
  <div class="key-manage">
    <template>
      <el-table class="table-input" :data="tableData" style="width: 100%">
        <el-table-column width="100" height="100">
          <i class="iconfont icon-icon-safe-light" />
        </el-table-column>
        <el-table-column :label="$t('key.remark')" align="center" width="200">
          <template slot-scope="scope">
            <span v-if="!scope.row.show">{{
              scope.row.desc ? scope.row.desc : "prometh"
            }}</span>
            <el-input
              v-else
              v-model="input"
              class="input"
              :autofocus="true"
              @keyup.enter.native="editKey(scope.row)"
              @blur="editKey(scope.row)"
              maxLength="9"
              max="9"
              ref="itemIpt"
            />
            <i class="iconFont el-icon-edit" @click="editClick(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="APIKEY" align="center" width="340">
          <template slot-scope="scope">
            <span class="keyFont">{{ scope.row.status === 'approved' ? scope.row.key : $i18n.locale === 'en'? 'in review' : '审核中' }}</span>
            <i
              v-if="scope.row.status === 'approved'"
              @click="copy(scope.row.key)"
              class="iconfont icon-fuzhiwenjian"
              style="marginleft: 10px"
            />
          </template>
        </el-table-column>
        <el-table-column
          class="time-container"
          align="center"
          :label="$t('key.time')"
          width="200"
        >
          <template slot-scope="scope">

          <span 
            class="timeover"
            v-if="scope.row.status === 'approved'"
          >2023.1.1</span>
          </template>

        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="delete-item"
              @click="handleDelete(scope.$index, scope.row)"
              v-loading="scope.row.loadingdel"
              >{{ $t("key.delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="box">
      <el-button
        class="create-key"
        round
        @click="addkeys"
        v-loading="loading"
        id="creationKey"
        >{{ $t("key.create") }}</el-button
      >
    </div>
  </div>
</template>
<script>
import { getkeys, addkeys, removekeys, editkeys } from "@/api/apiKeys";
import { userInfo } from "@/api/user";
import { toggle, toggleMessage, toggleError } from "@/utils/common";
export default {
  // key管理
  name: "KeyManage",
  data() {
    return {
      tableData: [],
      loading: false,
      input: "",
    };
  },
  mounted() {
    this.getkeys();
  },
  methods: {
    //获取key
    async getkeys() {
      const {
        data: { data },
      } = await getkeys();
      data.forEach((item) => {
        item.show = false;
        item.loadingdel = false;
      });
      this.tableData = data;
    },
    //编辑key
    async editClick(item) {
      if (item.show) {
        item.show = false;
      } else {
        item.show = true;
        this.input = "";
        this.$nextTick(() => {
          this.$refs.itemIpt.focus();
        });
      }
    },
    //编辑key
    async editKey(item) {
      if (this.input.trim() !== "") {
        item.desc = this.input;
        await editkeys(item);
        toggle("修改备注成功", "Change saved");
        this.getkeys();
        this.input = "";
        item.show = false;
      } else {
        item.show = false;
      }
    },
    //复制key
    copy(item) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = item;
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      toggle("复制成功", "Copied Successfully");
    },
    //删除key
    async handleDelete(index, row) {
      row.loadingdel = true;
      await removekeys(row.key);
      this.tableData.splice(index, 1);
      toggleMessage("删除成功", "Deleted Successfully");
      row.loadingdel = false;
    },
    //添加key
    async addkeys() {
      this.loading = true;
      const { data } = await userInfo();
      if (data.city) {
        if (this.tableData.length < 5) {
          await addkeys();
          this.getkeys();
          toggle("创建成功", "Created Successfully");
          this.loading = false;
        } else {
          toggleError(
            "最多只能拥有5条key",
            "You can only have 5 keys at most"
          );
          this.loading = false;
        }
      } else {
        this.$router.push("/personal-center/userinfo");
        this.loading = false;
        toggleMessage("将资料填写完整", "Please fill in the information completely");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.key-manage {
  .iconfont {
    color: #4885fe;
    width: 60px;
    height: 60px;
    font-size: 50px;
  }
  .icon-fuzhiwenjian,
  .iconFont {
    color: #4885fe;
    font-size: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
  .delete-item {
    color: #fff;
    background-color: #999999;
    border: none;
    width: 93px;
    height: 38px;
  }
  .keyFont {
    display: inline-block;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input {
    width: 60%;
  }
  .box {
    width: 100%;
    display: flex;
    justify-content: center;
    .create-key {
      margin-top: 100px;
      width: 166px;
      height: 51px;
      border-radius: 25px;
      color: #fff;
      background-color: #ffc322;
      border: none;
    }
  }
}
</style>
<style lang="scss">
.key-manage {
  .el-table {
    font-size: 16px;
    border-bottom: 1px solid #94ebe6 !important;
    .cell {
      line-height: normal !important;
    }
    .el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid #94ebe6 !important;
    }
  }
}
</style>
